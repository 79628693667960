import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'
import { graphql } from 'gatsby'
import BreadcrumbSection from '../components/breadcrumb'
import PopupSchedule from '../components/popupSchedule/PopupSchedule'

const PageTemplate = ({ data, location }) => {
     const { wpPage } = data
     const { sections, hideBreadcrumb, darkMode } = wpPage.cfPage
     const { titleTag, metaDescription, canonicalTagOverride, robotsDirective } = wpPage.cfPage.pageSeoFields
     const { ogTitle, ogDescription, ogType, twitterCard, ogImage } = wpPage.cfPage.pageSocialOpenGraphFields
     let breadcrumbItems = [
          {
               name: wpPage.title,
          },
     ]

     if (wpPage.wpParent) {
          const { title, slug } = wpPage.wpParent.node

          breadcrumbItems.unshift({ name: title, url: slug })
     }

     const darkModeClass = darkMode ? ' dark-mode' : ''

     const classBody = wpPage.title.split(' ').join('-').toLowerCase() + darkModeClass

     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const [showSchedule, setShowSchedule] = useState(false)



     useEffect(() => {
          if (showSchedule) {
               document.querySelector('html').setAttribute('style', 'overflow:hidden')
          } else {
               document.querySelector('html').setAttribute('style', 'overflow:initial')
          }
     }, [showSchedule])

     useEffect(() => {
          if (typeof window !== 'undefined') {
               if (window.location.href.includes("view-demo=1")) {
                    setShowSchedule(true);
               }
          }


     }, []);

     return (
          <Layout darkMode={darkMode}>
               <SEO
                    title={titleTag}
                    description={metaDescription}
                    canonical={canonicalTagOverride || canonical}
                    robotsDirective={robotsDirective}
                    ogTitle={ogTitle}
                    ogDescription={ogDescription}
                    ogImage={ogImage}
                    ogType={ogType}
                    twitterCard={twitterCard}
                    classBody={classBody}
                    breadcrumbItems={breadcrumbItems}
               />
               {!hideBreadcrumb && <BreadcrumbSection breadcrumbItems={breadcrumbItems} />}
               <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               {sections &&
                    sections.map((section, index) => {
                         return <Section key={index} sectionValues={section} />
                    })}
          </Layout>
     )
}

export default PageTemplate

export const pageQuery = graphql`
     query PageTemplate($uri: String!) {
          wpPage(uri: { eq: $uri }) {
               ...WordpressPageFields
          }
     }
`
